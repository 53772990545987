<template>
  <div class="electric-invoice page-container">
    <PageTitle title="請選擇電子發票登錄方式" />
    <!-- Setect Type -->
    <div class="electric-invoice_type">
      <div
        class="electric-invoice_type_item"
        @click="routeTo('/electricInvoicePhoto')"
      >
        <img src="@/assets/img/icon/camera.svg" />
        <p>拍照登錄</p>
      </div>
      <div
        class="electric-invoice_type_item"
        @click="routeTo('/electricInvoiceHand')"
      >
        <img src="@/assets/img/icon/edit.svg" />
        <p>手動登錄</p>
      </div>
    </div>
    <!-- btn -->
    <BaseBtn
      text="重新登錄發票類型"
      :isRounded="true"
      @click="routeTo('/invoiceLog')"
    />
    <BaseBtn
      text="回到首頁"
      type="back"
      :isRounded="true"
      :isOutlined="true"
      @click="routeTo('/home')"
    />
    <!-- Sample -->
    <div class="sample">
      <p class="sample_title">電子發票範本如下</p>
      <p class="sample_p">若發票類型錯誤，將不具抽獎資格</p>
      <img class="sample_img" src="@/assets/img/sample-electric.jpg" />
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import BaseBtn from "@/components/BaseBtn.vue";

export default {
  name: "ElectricInvoice",
  components: {
    PageTitle,
    BaseBtn,
  },
  data() {
    return {};
  },
  methods: {
    routeTo(route) {
      this.$router.push(route);
    },
  },
};
</script>
<style lang="scss">
.electric-invoice {
  &_type {
    margin-bottom: 25px;
    display: flex;
    &_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 110px;
      height: 110px;
      border: 1px solid #cccccc;
      border-radius: 12px;
      margin-right: 46px;
      &:last-child {
        margin-right: 0;
      }
      img {
        width: 40px;
        margin-bottom: 6px;
      }
      p {
        font-weight: 500;
      }
    }
  }
}
</style>
